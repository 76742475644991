import { useQuery } from '@tanstack/react-query';
import ClientService from '../services/ClientService';
import { currentClientAtom } from '../recoil/atoms/Clients';
import { useRecoilValue } from 'recoil';

const useFetchClientUsers = (includeDisabledUsers?: boolean) => {
  const currentClient = useRecoilValue(currentClientAtom);
  return useQuery({
    queryKey: ['clientUsers', currentClient?.id, includeDisabledUsers],
    staleTime: Infinity,
    queryFn: () => {
      return ClientService.getUsers(includeDisabledUsers);
    },
  });
};

export default useFetchClientUsers;
